@media print {
  body {
    font-size: 10pt;
  }
  header,
  footer,
  aside,
  .section-hero,
  .searchform,
  .tag-list-wrapper label {
    display: none !important;
  }
  .list {
    max-height: none;
  }
}